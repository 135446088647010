<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Filtrar por </strong>
      </CCardHeader>
      <CCardBody>
        <!-- Para buscar por proyecto-->
        <CRow>
          <CCol sm="3">
            <CInput
              type="date"
              label="Fecha Inicio"
              v-model="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="3">
            <CInput
              type="date"
              label="Fecha Fin"
              v-model="filter.date_end"
              :min="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="2">
            <label>&nbsp;</label>
            <br />
            <CButton type="submit" color="primary" @click="mtd_search_filter()">
              <i class="fa fa-search"></i> Buscar Lotes
            </CButton>
          </CCol>
          <CCol sm="2"> &nbsp; </CCol>
          <!-- <CCol sm="2"><br>
                  <CButton type="submit" color="success" @click="mtd_export_pdf()">
                      <i class="fa fa-file-pdf"></i> Reporte PDF
                  </CButton>
              </CCol> -->
          <!-- <CCol sm="2"><br>
                  <CButton type="submit" color="success" @click="mtd_export_excel()" v-if="load.excel == true">
                      <i class="fa fa-file-excel"></i> Reporte EXCEL
                  </CButton>
              </CCol> -->
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Lotes"
          icon="fas fa-dolly"
          btn_name="lotes"
          :size="'lg'"
          :button_new="false"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_view_view="mtd_view_view"
          @mtd_view_donwload="mtd_view_donwload"
        />
      </CCol>
    </CRow>
    <rptLote
      title="Detalle de lote"
      :boo_modal="modal_view.boo"
      :data="modal_view.data"
      @close_view="close_view"
    ></rptLote>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import rptLote from '../../components/shared/report/rptLote.vue';

const fields = [
  { key: "id", label: "Id", _style: "width:3%" },
  { key: "neto", label: "Peso neto", _style: "width:12%;" },
  { key: "prds", label: "Detalle", _style: "width:12%;" },
  { key: "date", label: "Fecha", _style: "width:12%;" },
  { key: "destination", label: "Destino", _style: "width:12%;" },
  { key: "guia", label: "Guia remisión", _style: "width:12%;" },
  { key: "transport", label: "Transportista", _style: "width:37%;" },
  { key: "asignation", label: "Asignación", _style: "width:37%;" },
];

export default {
  name: "v-report-lote",
  components: { CTableWrapper,rptLote },
  data() {
    return {
      prefix: "report/lote",
      fields,
      filter: {
        date_initial: null,
        date_end: null,
      },
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Ver detalles",
          action: "mtd_view_view",
          icon: "fas fa-eye",
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Descargar excel",
          action: "mtd_view_donwload",
          icon: "fas fa-file-excel",
        },
      ],
      modal_view: {
        boo: false,
        item: [],
      },
    };
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    this.filter.date_initial = fecha;
    this.filter.date_end = fecha;
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_search_filter: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          // console.log(response);
          this.data = response.data.map((item, order) => {
            return { ...item, order };
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_view_view: function(item){
      this.modal_view.boo=true;
      this.modal_view.data=item.detalle;
    },
    close_view: function(){
      this.modal_view = {
        boo: false,
        item: [],
      };
    },
    mtd_view_donwload: function(item){
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/excel",
        token: this.$store.getters.get__token,
        params: {
          lote: item.id,
          detail: item.detalle,
        },
      })
        .then((response) => {
          if (response.state == true) {
            window.open("/assets/excel/" + response.name , "_blank");
            bus.$emit("alert", {
              color: "success",
              message: "ARCHIVO DESCARGADO!",
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "ERROR AL DESCARGAR",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>